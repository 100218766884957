<template>
  <div class="container-fluid round px-0 mb-5">
    <div class="page-header mb-1">
      <strong>ค้นหาเลข</strong>
    </div>
    <div v-if="roundId" class="card">
      <RoundCardHeader />

      <div class="form-inline p-2">
        <label class="mr-2">ค้นหา </label>
        <input type="text" class="form-control mr-sm-2" placeholder="Agent Username" v-model="search.username">
        <select class="form-control mr-sm-2" v-model="search.numberType">
          <option value="threeNumberTop">3 ตัวบน</option>
          <option value="threeNumberTode">3 ตัวโต๊ด</option>
          <option value="twoNumberTop">2 ตัวบน</option>
          <option value="twoNumberBottom">2 ตัวล่าง</option>
          <option value="runTop">วิ่งบน</option>
          <option value="runBottom">วิ่งล่าง</option>
        </select>
        <input type="text" class="form-control mr-sm-2" placeholder="หมายเลข" v-model="search.number">
        <button type="button" class="btn btn-primary" @click="searchTickets">Submit</button>
      </div>

      <div class="px-2 pb-2">
        <table class="table table-bordered table-info table-hover mb-0">
          <thead>
            <tr>
              <th rowspan="2" width="60">ลำดับ</th>
              <th rowspan="2" class="pb-1">ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th rowspan="2">เวลา</th>
              <th rowspan="2" width="8%">ประเภท</th>
              <th rowspan="2" width="6%" class="border-right">หมายเลข</th>
              <th colspan="2" class="text-center border-right">Member</th>
              <th colspan="2" class="text-center border-right">Agent</th>
              <th colspan="2" class="text-center border-right">Company</th>
              <th rowspan="2">สถานะ</th>
              <th rowspan="2">หมายเหตุ</th>
              <th rowspan="2" width="90"></th>
            </tr>
            <tr>
              <th class="text-center" width="6%">จำนวน</th>
              <!-- <th class="text-center" width="6%">ส่วนลด</th> -->
              <!-- <th class="text-center" width="6%">สุทธิ</th> -->
              <th class="text-center border-right" width="6%">ถูกรางวัล</th>

              <th class="text-center" width="6%">ถือหุ้น</th>
              <!-- <th class="text-center" width="6%">ส่วนลด</th> -->
              <th class="text-center border-right" width="6%">ถูกรางวัล</th>
              <!-- <th class="text-center" width="6%">รวม</th> -->

              <th class="text-center" width="6%">ถือหุ้น</th>
              <!-- <th class="text-center" width="6%">ส่วนลด</th> -->
              <th class="text-center border-right" width="6%">ถูกรางวัล</th>
              <!-- <th class="text-center" width="6%">รวม</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item._id" :class="item.status==='Canceled'?'alert-danger':''">
              <td class="text-center">{{index+1}}</td>
              <td class="text-center">
                {{ item.account.mainUser.username }}
                <small v-if="showName" class="text-secondary">({{item.account.mainUser.firstName}})</small>
              </td>
              <td class="text-center">
                {{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}
              </td>
              <td class="text-center">{{item.text}}</td>
              <td class="text-center border-right text-primary">{{item.number}}</td>
              <td class="text-right text-danger">{{item.amount | amountFormat(2, '-')}}</td>
              <!-- <td class="text-right text-danger">{{item.discount | amountFormat(2, '-')}}</td> -->
              <!-- <td class="text-right text-success">{{item.credit | amountFormat(2, '-')}}</td> -->
              <td class="text-right text-success border-right">{{item.won | amountFormat(2, '-')}}</td>

              <td class="text-right text-success">{{item.agentAmount | amountFormat(2, '-')}}</td>
              <!-- <td class="text-right text-danger">{{item.agentCommission | amountFormat(2, '-')}}</td> -->
              <td class="text-right text-danger border-right">{{item.agentWon | amountFormat(2, '-')}}</td>
              <!-- <td class="text-right text-success">{{item.agentTotal | amountFormat(2, '-')}}</td> -->

              <td class="text-right text-success">{{item.companyAmount | amountFormat(2, '-')}}</td>
              <!-- <td class="text-right text-danger">{{item.companyCommission | amountFormat(2, '-')}}</td> -->
              <td class="text-right text-danger border-right">{{item.companyWon | amountFormat(2, '-')}}</td>
              <!-- <td class="text-right text-success">{{item.companyTotal | amountFormat(2, '-')}}</td> -->

              <td class="text-center">
                <NumberStatus :status="item.status" />
              </td>
              <td class="text-center">{{item.remarks}}</td>
              <td class="text-left">
                <button class="btn btn-outline-info btn-sm" title="ดูรายการแทง" @click="viewTicket(item.ticketId)"><i class="fas fa-list"></i></button>
                <button v-if="item.agentAmount > 0" class="btn btn-warning btn-sm ml-2" title="แบ่งของให้บริษัท" @click="recalculate(item)"><i class="fas fa-undo"></i></button>
              </td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="20" class="text-center">กรุณารอซักครู่</td>
            </tr>
          </tbody>
          <tbody v-if="!isLoading && !this.items.length">
            <tr>
              <td colspan="20" class="text-center">ไม่พบรายการ</td>
            </tr>
          </tbody>
          <tfoot v-if="!isLoading && this.items.length">
            <tr>
              <td colspan="5" class="border-right text-center">รวม</td>
              <td class="text-right text-danger">{{ summary.amount | amountFormat(2, '-') }}</td>
              <!-- <td class="text-right text-danger">{{ summary.discount | amountFormat(2, '-') }}</td> -->
              <!-- <td class="text-right text-danger">{{ summary.credit | amountFormat(2, '-') }}</td> -->
              <td class="text-right text-success border-right">{{ summary.won | amountFormat(2, '-') }}</td>

              <td class="text-right text-success">{{summary.agentAmount | amountFormat(2, '-')}}</td>
              <!-- <td class="text-right text-danger">{{summary.agentCommission | amountFormat(2, '-')}}</td> -->
              <td class="text-right text-danger border-right">{{summary.agentWon | amountFormat(2, '-')}}</td>
              <!-- <td class="text-right text-success">{{summary.agentTotal | amountFormat(2, '-')}}</td> -->

              <td class="text-right text-success">{{summary.companyAmount | amountFormat(2, '-')}}</td>
              <!-- <td class="text-right text-danger">{{summary.companyCommission | amountFormat(2, '-')}}</td> -->
              <td class="text-right text-danger border-right">{{summary.companyWon | amountFormat(2, '-')}}</td>
              <!-- <td class="text-right text-success">{{summary.companyTotal | amountFormat(2, '-')}}</td> -->

              <td colspan="3"></td>
            </tr>
          </tfoot>
        </table>
      </div>

    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>

    <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import cryptoRandomString from 'crypto-random-string'
import LottoService from '@/services/lottoService'
import RoundCardHeader from '@/views/manage-lotto/components/RoundCardHeader'
import TicketStatus from '@/views/components/TicketStatus'
import ViewTicketModal from '@/views/manage-lotto/components/ViewTicketModal'

export default {
  name: 'SearchTickets',
  components: {
    RoundCardHeader,
    TicketStatus,
    ViewTicketModal
  },
  data() {
    return {
      search: {
        username: '',
        numberType: 'twoNumberTop',
        number: ''
      },
      data: null,
      isLoading: false,
      showName: false,
      isShowTicketModal: false,
      viewTicketId: null,
      agentUsername: null
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    },
    items() {
      if(!this.data)
        return []

      return this.data
    },
    summary() {
      return this.items.reduce((sum, item)=>{
        sum.amount += item.amount
        sum.discount += item.discount
        sum.credit += item.credit
        sum.won += item.won
        sum.agentAmount += item.agentAmount
        sum.agentCommission += item.agentCommission
        sum.agentTotal += item.agentTotal
        sum.agentWon += item.agentWon
        sum.companyAmount += item.companyAmount
        sum.companyCommission += item.companyCommission
        sum.companyTotal += item.companyTotal
        sum.companyWon += item.companyWon
        return sum
      }, {
        amount: 0,
        discount: 0,
        credit: 0,
        won: 0,
        agentAmount: 0,
        agentCommission: 0,
        agentTotal: 0,
        agentWon: 0,
        companyAmount: 0,
        companyCommission: 0,
        companyTotal: 0,
        companyWon: 0
      })
    }
  },
  methods: {
    searchTickets() {
      if(!this.search.username) {
        return Swal.fire({
          title: 'ผิดพลาด!',
          text: 'กรุณาระบุ Username',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      if(!this.search.number) {
        return Swal.fire({
          title: 'ผิดพลาด!',
          text: 'กรุณาระบุหมายเลข',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      this.isLoading = true
      this.agentUsername = this.search.username
      LottoService.searchNumbers(this.roundId, this.search)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    },
    recalculate(item) {
      console.log(item)
      Swal.fire({
        text: 'จำนวนที่ต้องการแบ่งให้บริษัท',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: (amount) => {
          if(amount > 0 && parseFloat(amount) <= item.amount) {
            LottoService.shareAmount(item._id, {
              username: this.agentUsername,
              amount: parseFloat(amount)
            })
            .then((response)=>{
              if(response.success) {
                this.searchTickets()
                return response?.data
              }else{
                throw new Error(response?.data)
              }
            })
            .catch((error)=>{
              Swal.showValidationMessage(error.message)
            })
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'success'
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.swal2-input {
  width: 100%;
}
.table {
  .border-right {
    border-right: 2px solid #86cfda !important;
  }
  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
        line-height: 1;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 85%;
      }
      th {
        padding: 5px;
        font-size: 85%;
      }
    }
  }
  tfoot {
    tr {
      td {
        padding: 5px;
        font-size: 85%;
      }
    }
  }
}
</style>
